import {Navbar,Container, Nav} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';


const Header = (props) => {
    const location = useLocation()
    const hide = (location.pathname == "/")
    return (
        <>
        {/* Nav bar with Just City */}
        <Navbar className="navbar-custom" variant="dark" expand="xl" sticky="top">
        <Container className="navbar-container">
            <Navbar.Brand className="" href="#home"><span className={"on-brand1"}>JUST</span> <span className={"on-brand2"}>DATA</span>beta</Navbar.Brand>
        </Container>
        </Navbar> 
    
        </>
    

    )
}

export default Header;