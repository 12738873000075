import {Container, Row, Col} from 'react-bootstrap';



const Argument = (props) => {
   
    return(
        <>
        <Container fluid style={{"padding" :"5%"}}> 
            <Row style={{}}>

                {/* Title */}

                <Col sm={12}>
                    <h1 style={{"color": "#C44848"}}>{props.title}</h1>
                </Col> 

                {/* Exposition  */}
                
                <Col sm={12} >
                    <div style={{"width":"75%", "margin": "auto"}}>
                        <h2>{props.titleexposition}</h2>
                        <p>{props.children}</p>
                    </div>
                </Col>

                {/* Picture/Visualization prop  */}

                <Col sm={12} style={{marginBottom: "0px"}}>
                    { <iframe src={props.picture}           
                    width={"100%"}
                    height={props.height}
                    scrolling="no"
                    style={{ overflow: "hidden", marginBottom:"0", paddingBottom:"0"}}/>}          
                </Col>

                <Col sm={12} width={"90%"}>
                    {props.footnote}
                </Col>



            </Row>
        </Container>
        
        </>
    )
}

export default Argument;
 