import './App.css';
import './components/Header.css'
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import {Route, Routes} from 'react-router-dom';
import Landing from './pages/Landing'



function App() {

  return (
    <div className="App">

      <Header/>
      <main>
      <Routes>
        <Route path="/" element={<Landing />} />
      </Routes>
      </main>

  </div>
  );
}

export default App;
