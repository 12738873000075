import Argument from "../components/Argument";
import Footnote from "../components/Footnote";
import { Tabs, Tab } from "react-bootstrap";

const Landing = () => {
  return (
    <>
      <Tabs
        fill
        justify
        defaultActiveKey="pretrial"
        id="uncontrolled-tab-example"
        className="mb-3 justify-content-center"
        style={{ "font-size": "20px", "background-color": "#182d3a" }}
      >
        <Tab eventKey="pretrial" title="Jail Population" className="">
          <Argument
            title={"Jail Population"}
            titleexposition={""}
            picture={`${process.env.REACT_APP_WEBSITE_URL}/viz/jail_pop_over_time`}
            height={"600px"}
            footnote={jailPopFootnote}
          >
            The following plot uses bookings after June 13, 2019 to track how
            many people are in jail or enter the jail each day. This includes
            people who are booked and released on the same day as well as people
            who remain in jail for years awaiting trial. 
          </Argument>
          <Argument
            title={"Average Length of Stay"}
            titleexposition={""}
            picture={`${process.env.REACT_APP_WEBSITE_URL}/viz/los`}
            height={"600px"}
          >
            The following plot uses bookings after June 13, 2019 to compute the
            "average length of stay" which is the average number of days
            a person stays in jail after getting arrested.
          </Argument>
        </Tab>
        <Tab eventKey="bail" title="Bail" className="">
          <Argument
            title={"Bail vs Offense"}
            titleexposition={""}
            picture={`${process.env.REACT_APP_WEBSITE_URL}/viz/bail_byoffense`}
            height={"1000px"}
            footnote={bailFootnote}
          >
            The following plot uses all non-expunged cases for which the initial
            bond setting occurred after January 1, 2018. For each case, we
            define the "bond amount" to be the initial setting amount. As
            opposed to visualizations in the "Pretrial" section which aggregate
            at the booking level, here the "Max Charge Level" and "Max Offense"
            dropdown menus filter at the case level. Given the specified
            dropdown filter values, the top plot tracks the average initial bail
            amount over time by race, and the second plot illustrates the
            distribution (median, quartiles, extremes, etc...) of initial bail
            amounts by race.
          </Argument>
        </Tab>
        <Tab eventKey="charges" title="Disposition" className="">
          <Argument
            title={"Charge vs Disposition"}
            titleexposition={""}
            picture={`${process.env.REACT_APP_WEBSITE_URL}/viz/charge_byoffense`}
            height={"1750px"}
            footnote={dispositionFootnote}
          >
            The following plot uses all cases such that the initial disposition
            on the case occurred after January 1st, 2017. The top plot shows the
            percentage of dispositions that match a certain disposition type --
            defined in the "Disposition Type" dropdown menu -- over time broken
            out by race. The lower plot aggregates all disposition types over
            all years to show the total share of cases across those different
            disposition types.
          </Argument>
        </Tab>
      </Tabs>
    </>
  );
};

export default Landing;

const bailFootnote = (
  <Footnote>
    <ul>
      <li>
        Non-criminal cases are consistently excluded from analyses on this site.
        Criminal cases where the defendant is held without bond are also
        excluded from the analysis below but will be explored and juxtaposed to
        ROR and money bail rates in upcoming visualizations. RORs are included
        in the analyses below and counted as $0.
      </li>
      <li>
        The "____ Cases Displayed" statistic in the visualization title
        represents the number of distinct cases that remain after the filters
        have been applied and appropriate cases dropped.
      </li>
      <li>
        Paula Skahan is missing data from Shelby County's criminal justice
        database until late 2018, so a nontrivial number of relevant cases are
        temporarily missing from the dataset.
      </li>
      <li>
        For filter selections with wide ranges of bond values, the box plot
        becomes compressed and hard to read. In these situations, simply hover
        the mouse near the plot corresponding to each race to see the
        statistics.
      </li>
    </ul>
  </Footnote>
);

const jailPopFootnote = (
  <Footnote>
    <ul>
      <li>
        The "Unit of Time" dropdown allows the user to find the aggregated mean
        over months and years.
      </li>
      <li>
        The jail population counts displayed in this plot are underestimates
        since we cannot guarantee inclusion of every person who is booked and
        then released 20 minutes later.
      </li>
      <li>
        The "Over 500 Days" count only includes a person/booking after they
        reach their 500th day.
      </li>
      <li>
        Using the "Max Charge Level" dropdown updates the visualization to
        display the average length of bookings such that, for example, Felony B
        is the highest charge on the booking. Our explicit "ranking" of charge
        types can be found by clicking/expanding the "Max Charge Level" dropdown
        in which the charge types are listed in ascending order.{" "}
      </li>
      <li>
        Selecting "Max Offense" filters bookings such that the selected offense
        corresponds to the maximum charge level. These dropdowns will be common
        throughout the rest of the visualizations on this site.
      </li>
    </ul>
  </Footnote>
);

const losFootnote = (
  <Footnote>

  </Footnote>
);

const dispositionFootnote = (
  <Footnote>
    <ul>
      <li>
        Cases can have multiple charges. We label a case as "GUILTY_CONVICTED"
        or "GUILTY_PLEA" if any one charge on that case is disposed as guilty,
        regardless of the number of charges dismissed.
      </li>
      <li>
        Active cases, cases that were eventually held to state, and non-criminal
        cases were dropped from the analysis below.
      </li>
      <li>
        Diversions that are active, diversions that were eventually terminated,
        and diversions that were successfully completed are all labeled as
        "DIVERSION".
      </li>
      <li>
        Data may underestimate the number and/or proportion of diversions,
        dismissed, or nolle pros dispositions due to expungement.
      </li>
      <li>
        Race = "Hispanic" includes all cases or bookings that are associated to
        people whose ethnicity is labeled as "Hispanic or Latino" in the court
        system database, but because Hispanic people are not consistently
        labeled correctly in the system, Hispanic ethnicity is also inferred
        from the most common Hispanic names from the 2020 Census. Additionally, we
        focus on inferring ethnicity only after June 13, 2019, so data points
        corresponding to Hispanic people before that date are an underestimate.
      </li>
    </ul>
  </Footnote>
);
